// HTML Images
import html5logo from '../../../assets/img/html5-logo.jpg';
import jsxlogo from '../../../assets/img/jsx-logo.jpg';
import handlebarsLogo from '../../../assets/img/handlebars-logo.jpg';

// CSS Images
import css3logo from '../../../assets/img/css3-logo.jpg';
import sasslogo from '../../../assets/img/sass-logo.jpg';
import bootstraplogo from '../../../assets/img/bootstrap-logo.jpg'
import materialui from '../../../assets/img/materialui-logo.jpg';

// JS images
import jslogo from '../../../assets/img/javascript-logo.jpg';

// Backend
import springlogo from '../../../assets/img/spring-logo.jpg';
import postgreslogo from '../../../assets/img/postgres-logo.jpg'

// Others
import jenkinslogo from '../../../assets/img/jenkins-logo.jpg';
import githublogo from '../../../assets/img/github-logo.jpg';
import jiralogo from '../../../assets/img/jira-logo.jpg';


const SkillData = [
    {
        title: 'HTML',
        tools: [
            {
                name: 'HTML5',
                imagesrc: html5logo
            },
            {
                name: 'JSX',
                imagesrc: jsxlogo
            },
            {
                name: 'Handlebars',
                imagesrc: handlebarsLogo
            },
        ]
    },
    {
        title: 'CSS',
        tools: [
            {
                name: 'CSS3',
                imagesrc: css3logo
            },
            {
                name: 'SASS',
                imagesrc: sasslogo
            },
            {
                name: 'Bootstrap',
                imagesrc: bootstraplogo
            },
            {
                name: 'Material-UI',
                imagesrc: materialui
            }
        ]
    },
    {
        title: 'Javascript',
        tools: [
            {
                name: 'JS ES8',
                imagesrc: jslogo
            },
            {
                name: 'React',
                imagesrc: jsxlogo
            }
        ]
    },
    {
        title: 'Back-end',
        tools: [
            {
                name: 'Spring',
                imagesrc: springlogo
            },
            {
                name: 'Postgres',
                imagesrc: postgreslogo
            }
        ]
    },
    {
        title: 'Other',
        tools: [
            {
                name: 'Jenkins',
                imagesrc: jenkinslogo
            },
            {
                name: 'Github',
                imagesrc: githublogo
            },
            {
                name: 'Jira',
                imagesrc: jiralogo
            }
        ]
    }
];

export default SkillData;
