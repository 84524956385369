const LandingData = {

    headerText: "I am Dirk Heijnen, Sofware Engineer",

    mission: "My goal is to design and write software that will improve the quality of life for companies and people alike.",

    githubLink: "https://github.com/DirkHeijnen",
    linkedInLink: "https://www.linkedin.com/in/dirk-heijnen-0773b1140",
    mail: "mailto:dirkheijnen95@gmail.com"
};

export default LandingData;
