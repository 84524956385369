import React from 'react';

import ProjectCardBack from './project-card-back/project-card-back.component';
import ProjectCardFront from './project-card-front/project-card-front.component';

import { useWindowSize } from '../../../app/App';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import useStyles from './project-card.styles';

const ProjectCard = ({ project }) => {

    const [info, setInfo] = React.useState(false);
    const MAX_DESCRIPTION_LENGTH = useWindowSize().width < 500 ? 75 : 125

    const switchInfo = () => {
        setInfo(!info);
    };

    const { card } = useStyles()

    return (
        <Card className={card}>
            {
                info ?
                    <ProjectCardBack switchInfo={switchInfo} project={project} />
                    :
                    <ProjectCardFront switchInfo={switchInfo} project={project} maxLength={MAX_DESCRIPTION_LENGTH} />
            }

            <Divider />
            <CardActions>
                <Button size="small" color="primary" onClick={() => window.open(`${project.githublink}`, "_blank")}>Github</Button>
                <Button size="small" color="primary" onClick={() => window.open(`${project.projectlink}`, "_blank")}>Demo</Button>
            </CardActions>
        </Card>
    )
};

export default ProjectCard;
