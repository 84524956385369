import React from 'react';

import SectionHeader from '../../section-header/section-header.component';
import SkillCard from '../../cards/skill-card/skill-card.component';
import { default as skills } from './skill-section.data';

import Grid from '@material-ui/core/Grid';

import useStyles from './skill-section.styles';

const SkillSection = () => {
    const { paper } = useStyles();

    return (
        <Grid direction='row' justify='center' alignItems='center' id='skills'>
            <SectionHeader title='SKILLS' />

            <Grid container className={paper} direction='row' justify="flex-start" alignItems="center">
                {
                    skills
                        .map(({ title, tools }) =>
                            <Grid item xs={12} >
                                <SkillCard title={title} tools={tools} />
                            </Grid>
                        )
                }
            </Grid>

        </Grid>
    );
}

export default SkillSection;
