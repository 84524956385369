import React from 'react';

import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import Typography from '@material-ui/core/Typography';

import useStyles from './project-card-front.styles';

const ProjectCardFront = ({ switchInfo, project, maxLength }) => {
    const classes = useStyles()

    return (
        <CardActionArea onClick={switchInfo}>
            <CardMedia
                component="img"
                alt="Contemplative Reptile"
                height="180"
                image={project.imagesrc}
                title="Contemplative Reptile"
            />
            <CardContent className={classes.cardcontent}>

                <Typography className={classes.title} variant="h5" component="h2">{project.title}</Typography>

                <Typography className={classes.text} variant="body2" color="textSecondary" component="p">
                    {
                        project.description.length > maxLength ?
                            `${project.description.substring(0, maxLength)}...`:
                            `${project.description}`
                    }
                </Typography>
            </CardContent>
        </CardActionArea>
    )
};

export default ProjectCardFront;
