import { makeStyles } from '@material-ui/core';
import backgroundimage from '../../assets/img/backgroundimage.png';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('xl')]: {
            marginTop: 0,
            paddingLeft: 222
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 64,
            paddingLeft: 0
        },

        backgroundImage: `url(${backgroundimage})`,
        backgroundRepeat: 'repeat'
    },
}));

export default useStyles;
