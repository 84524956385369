import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    card: {
        margin: 10,
        [theme.breakpoints.down('xl')]: {
            maxWidth: '20vw',
            minWidth: '20vw',
        },
        [theme.breakpoints.down('lg')]: {
            maxWidth: '20vw',
            minWidth: '20vw',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '30vw',
            minWidth: '30vw',
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '42vw',
            minWidth: '42vw',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '70vw',
            minWidth: '70vw',
        },

        minHeight: 350,
        maxHeight: 350,
        boxShadow: '0px 4px 5px 3px rgba(0,0,0,0.36)'
    }
}));

export default useStyles;
