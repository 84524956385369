import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    title: {
        marginBottom: 5
    },
    text: {
        minHeight: 45
    },
    cardcontent: {
        minHeight: 127,
        maxHeight: 127
    }
});

export default useStyles;
