const AboutMeData = {

    missionStatement: "Aspiring software engineering student with a passion for fullstack development and the lastest technologies.",

    introduction: "I'm 24 years old, current studying at the Fontys University of Applied Sciences in Eindhoven.",

    career: "Before I started this study, I've studied sports and tourism, where I worked internationally with." +
        "When I got the opportunity to work for a big telecom company as frontoffice I took this chance, where I ended up growing to the " +
        "backoffice role. During this time I became familiar with programming via SoloLearn, this made me get back into studying one again, which I'm very happy with.",

    personal: "In my free time I like to go to the gym and work on side projects. " +
        "I'm also a big fan of heavy metal and deathcore and love to go to music concerts.",

    focusPoints: [
        "Machine learning",
        "Microservices with Spring Boot",
        "React with Redux",
        ".NET Core 3.0"
    ]

};

export default AboutMeData;
