import React from 'react';

import SkillIcon from '../../skill-icon/skill-icon.component';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import useStyles from './skill-card.styles';

const SkillCard = ({ title, tools }) =>  {
    const { cardContainer, cardTitle, skillContainer, skill  } = useStyles();

    return (
        <div>
            <Paper className={cardContainer}>
                <Grid direction='row' justify='flex-start' alignItems='center'>

                    <Grid item xs={12}>
                        <Typography className={cardTitle}>{title}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Divider />
                    </Grid>

                    <Grid container direction='row' justify="flex-start" alignItems="center" className={skillContainer}>
                        {
                            tools
                                .map(tool =>
                                    <Grid item xs={6} sm={4} md={3} lg={3} xl={3} className={skill}>
                                        <SkillIcon tool={tool} />
                                    </Grid>
                                )
                        }
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

export default SkillCard;
