import React from 'react';

import { useWindowSize } from '../../../app/App';

import { Link } from 'react-scroll/modules';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const SidebarItem = ({ icon, name, to, toggleDrawer }) => {

    return (
        <Link
            activeClass='active'
            to={to}
            spy={false}
            smooth={true}
            offset={useWindowSize() < 960 ? -64 : -0}
            duration={500}
        >
            <ListItem button key={name} onClick={toggleDrawer('left', false)}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={name} />
            </ListItem>
        </Link>
    )
}

export default SidebarItem;
