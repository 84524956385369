import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    paper: {
        marginTop: 30,
        marginBottom: 30
    }
});

export default useStyles;
