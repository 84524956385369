import smartBrainProject from '../../../assets/img/smartbrain-project.PNG';

const ProjectsData = [
    {
        title: 'Smart Brain',
        imagesrc:  smartBrainProject,
        description: 'A project written with React and Express that shows',
        accomplishments: [
            "React.JS Frontend and Express backend",
            "Used machine learning models for facial recognition",
        ],
        githublink: 'https://github.com/DirkHeijnen/SmartBrainFrontend',
        projectlink: 'https://ztm-smartbrain-frontend.herokuapp.com/'
    },
];

export default ProjectsData;

