import React, { useEffect, useState } from 'react';

import Navbar from '../components/navigation/navbar/navbar.component';
import MobileSidebar from '../components/navigation/mobile-sidebar/mobile-sidebar.component';
import MainContent from '../components/main-content/main-content.component';
import PcSidebar from '../components/navigation/pc-sidebar/pc-sidebar.component';

export const useWindowSize = () => {
    const isClient = typeof window === 'object';

    const getSize = () => {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        const handleResize = () => {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

const App = () => {

    const [state, setState] = React.useState({ left: false, });

    const toggleDrawer = (side, open) => () => { setState({ ...state, [side]: open }) };

    return (
        <div className='app'>

            {
                useWindowSize().width < 960 ?
                    <React.Fragment>
                        <Navbar title='Software Engineer' fullName='Dirk Heijnen' toggleDrawer={toggleDrawer} />
                        <MobileSidebar toggleDrawer={toggleDrawer} left={state.left} />
                    </React.Fragment> :
                        <PcSidebar />
            }

            <MainContent />
        </div>
    )

}

export default App;
