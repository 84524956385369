import React from 'react';

import ProjectCard from '../../cards/project-card/project-card.component';
import SectionHeader from '../../section-header/section-header.component';
import { default as projects } from './projects-section.data';

import Grid from '@material-ui/core/Grid';

import useStyles from './projects-section.styles';

const ProjectsSection = () => {
    const { projectsContainer, projectCardContainer } = useStyles();

    return (
        <Grid direction='row' justify='center' alignItems='center' id='projects'>
            <SectionHeader title='PROJECTS' />

            <Grid container className={projectsContainer} direction='row' justify="flex-start" alignItems="center">
                {
                    projects
                        .map(project =>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} className={projectCardContainer} >
                                <ProjectCard project={project} />
                            </Grid>
                        )
                }
            </Grid>
        </Grid>
    );
}

export default ProjectsSection;
