import React from 'react';

import LandingSection from '../sections/landing-section/landing-section.component';
import AboutSection from '../sections/about-section/about-section.component';
import ProjectsSection from '../sections/project-section/projects-section.component';
import ChallengesSection from '../sections/challenges-section/challenges-section.component';
import SkillSection from '../sections/skill-section/skill-section.component';

import useStyles from './main-content.styles';


const MainContent = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <LandingSection />
            <AboutSection />
            <ProjectsSection />
            <ChallengesSection />
            <SkillSection />
        </div>

    );
}

export default MainContent;
