import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/App';

import { CssBaseline } from '@material-ui/core';

ReactDOM.render(
    <>
        <CssBaseline />
        <App />
    </>,
    document.getElementById('root')
);
