import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    iconContainer: {
        [theme.breakpoints.down('xl')]: {
            display: 'flex',
            flexDirection: 'column',
            width: 100,
        },
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
            width: 75,
        },
        marginBottom: 30
    },
    skillIcon: {
        [theme.breakpoints.down('xs')]:{
            width: 75,
            height: 75
        },
        width: 100,
        height: 100
    },
    skillName: {
        textAlign: 'center',
        marginTop: 15,
        fontSize: 16,
        fontWeight: 400
    }
}));

export default useStyles;
