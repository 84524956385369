import herculesProject from '../../../assets/img/hercules-project.PNG';

const ChallengesData = [
    {
        title: 'Hercules',
        imagesrc: herculesProject,
        description: `HTML/CSS only website for a coding contest. Got 2nd place and won Creative Tim's big bundle.`,
        accomplishments: [
            'Fully responsive HTML/CSS only project',
            "Used Creative Tim's Now UI kit",
            'Won 2nd place at a styling contest',
        ],
        githublink: 'https://github.com/DirkHeijnen/Coding-Challenge-8',
        projectlink: 'https://dirkheijnen.github.io/Coding-Challenge-8/'
    },
];

export default ChallengesData;
