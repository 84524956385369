import React from 'react';

import useStyles from './skill-icon.styles';

const SkillIcon = ({ tool }) => {
    const { iconContainer, skillIcon, skillName } = useStyles();
    const { name, imagesrc } = tool;

    return (
        <div className={iconContainer}>
            <img className={skillIcon} src={imagesrc} alt={name} />
            <span className={skillName}>{name}</span>
        </div>
    )
}

export default SkillIcon;
