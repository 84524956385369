import React from 'react';

import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import useStyles from './project-card-back.styles';
import dense from '@material-ui/core/List/ListContext';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';


import Divider from '@material-ui/core/Divider';

const ProjectCardBack = ({ switchInfo, project }) => {
    const { title, listContainer } = useStyles()

    return (
        <CardActionArea onClick={switchInfo}>
            <CardContent>
                <Typography className={title} variant="h5" component="h2">Achievements</Typography>
                <Divider />
                <List dense={dense} className={listContainer}>
                    {
                        project.accomplishments.map(accomplishment =>
                            <ListItem>
                                <ListItemIcon>
                                    <ArrowRightIcon />
                                </ListItemIcon>
                                <ListItemText primary={accomplishment}/>
                            </ListItem>
                        )
                    }
                </List>
            </CardContent>
        </CardActionArea>
    )
};

export default ProjectCardBack;
