import React from 'react';

import SidebarItem from '../sidebar-item/sidebar-item.component';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PollIcon from '@material-ui/icons/Poll';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WorkIcon from '@material-ui/icons/Work';
import EmojiEventsSharpIcon from '@material-ui/icons/EmojiEventsSharp';

const MobileSidebar = ({ toggleDrawer, left }) => {

    return (
        <SwipeableDrawer
            open={left}
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}
        >
            <List>
                <SidebarItem
                    to='about'
                    icon={<AssignmentIndIcon />}
                    toggleDrawer={() => toggleDrawer('left', false)}
                    name='About me'
                />
                <SidebarItem
                    to='projects'
                    icon={<AssignmentIcon />}
                    toggleDrawer={() => toggleDrawer('left', false)}
                    name='Projects'
                />
                <SidebarItem
                    to='challenges'
                    icon={<EmojiEventsSharpIcon />}
                    toggleDrawer={() => toggleDrawer('left', false)}
                    name='Coding challenges'
                />
                <SidebarItem
                    to='skills'
                    icon={<PollIcon />}
                    toggleDrawer={() => toggleDrawer('left', false)}
                    name='Skills'
                />
            </List>
            <Divider />
            <List>
                <ListItem button key={'CV'} onClick={() => {
                    toggleDrawer('left', false);
                    window.open("https://drive.google.com/open?id=1W4BNhR0fqjcgcT1vxxCOU6RaSIZUncrh", "_blank")
                }}>
                    <ListItemIcon><WorkIcon /></ListItemIcon>
                    <ListItemText primary='CV' />
                </ListItem>
            </List>
        </SwipeableDrawer>
    );
};

export default MobileSidebar;
