import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    sectionHeader: {
        backgroundColor: 'rgb(71,171,91)',
        zIndex: 1050,
    },
});

export default useStyles;
