import React from 'react';

import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PollIcon from '@material-ui/icons/Poll';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WorkIcon from '@material-ui/icons/Work';
import EmojiEventsSharpIcon from '@material-ui/icons/EmojiEventsSharp';

import SidebarItem from '../sidebar-item/sidebar-item.component';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';

import useStyles from './pc-sidebar.styles.jsx';

const PcSidebar = () => {
    const { nameContainer, nameText, titleText } = useStyles();

    return (
        <Drawer
            variant="permanent"
            anchor="left"
        >
            <div className={nameContainer}>
                <Typography className={nameText}>Dirk Heijnen</Typography>
                <Typography className={titleText}>Software Engineer</Typography>
            </div>

            <Divider />
            <List>
                <SidebarItem
                    to='about'
                    icon={<AssignmentIndIcon />}
                    toggleDrawer={() => null}
                    name='About me'
                />
                <SidebarItem
                    to='projects'
                    icon={<AssignmentIcon />}
                    toggleDrawer={() => null}
                    name='Projects'
                />
                <SidebarItem
                    to='challenges'
                    icon={<EmojiEventsSharpIcon />}
                    toggleDrawer={() => null}
                    name='Coding challenges'
                />
                <SidebarItem
                    to='skills'
                    icon={<PollIcon />}
                    toggleDrawer={() => null}
                    name='Skills'
                />
            </List>
            <Divider />
            <List>
                <ListItem button key={'CV'} onClick={() => {
                    window.open("https://drive.google.com/open?id=1W4BNhR0fqjcgcT1vxxCOU6RaSIZUncrh", "_blank")
                }}>
                    <ListItemIcon><WorkIcon /></ListItemIcon>
                    <ListItemText primary='CV' />
                </ListItem>
            </List>
        </Drawer>
    );
}

export default PcSidebar;
