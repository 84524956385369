import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    landingPageContainer: {
        [theme.breakpoints.down('xl')]: {
            minHeight: '85vh',
        },
        [theme.breakpoints.down('lg')]: {
            minHeight: 920,
            maxHeight: 920,
            height: 920,
        },
        [theme.breakpoints.down('md')]: {
            minHeight: 820,
            maxHeight: 820,
            height: 820,
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: 800,
            maxHeight: 800,
            height: 800,
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: 670,
            maxHeight: 670,
            height: 670,
        },

        background: "linear-gradient(180deg, rgba(58,201,89,0.6) 0%, rgba(58,201,89,0.6) 5%, rgba(26,144,105,0.6) 69%)",
        zIndex: -2
    },
    particles: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    infoContainer: {
        paddingTop: 50,
        marginLeft: '5vw',
        marginRight: '5vw',
        textAlign:'center',
    },
    infoText: {
        [theme.breakpoints.down('xl')]: {
            marginTop: 50,
            fontSize: 40,
            marginBottom: 20
        },
        [theme.breakpoints.down('lg')]: {
            marginTop: 50,
            fontSize: 32,
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 70,
            fontSize: 32,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            fontSize: 24,
        },
        fontWeight: 400,
        lineHeight: '125%',

    },
    missionContainer: {
        paddingTop: 30,
        marginLeft: '5vw',
        marginRight: '5vw',
        textAlign:'center'
    },
    missionText: {
        [theme.breakpoints.down('xl')]: {
            fontSize: 20,
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
        },
        fontWeight: 300,
        lineHeight: '125%'
    },
    avatarContainer: {
        [theme.breakpoints.down('xl')]: {
            marginTop: 25
        },
        display: 'flex',
        justifyContent: 'center',
    },
    avatar: {
        [theme.breakpoints.down('xl')]: {
            width: 200,
            height: 200,
            marginTop: 40
        },
        [theme.breakpoints.down('xs')]: {
            width: 150,
            height: 150,
            marginTop: 30
        },
        margin: 10,

    },
    divider: {
        [theme.breakpoints.down('xl')]: {
            width: '30vw'
        },
        [theme.breakpoints.down('lg')]: {
            width: '40vw'
        },
        [theme.breakpoints.down('sm')]: {
            width: '50vw'
        },
        marginTop: 15,
        margin: '0 auto',
        borderBottom: '0.5px solid lightgrey'
    },
    contactContainer:{
        marginTop: 25,
        display: 'flex',
        justifyContent: 'center',
    },
    contactIcon: {
        [theme.breakpoints.down('xl')]: {
            height: 60,
            width: 60,
            marginLeft: 15,
            marginRight: 15,
        },
        [theme.breakpoints.down('sm')]: {
            height: 50,
            width: 50,
            marginLeft: 15,
            marginRight: 15,
        },
        zIndex: 3,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    contactText: {
        [theme.breakpoints.down('xl')]: {
            fontSize: 36,
            fontWeight: 300,
            marginTop: 30
        },
        [theme.breakpoints.down('md')]: {
            fontSize: 28,
            fontWeight: 300,
            marginTop: 20
        }

    },
    textArea: {
        [theme.breakpoints.down('xl')]: {
            marginLeft: '25vw',
            marginRight: '25vw'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '15vw',
            marginRight: '15vw'
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '5vw',
            marginRight: '5vw'
        }

    }
}));

export default useStyles;
