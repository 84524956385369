import React from 'react';

import SectionHeader from '../../section-header/section-header.component';
import ProjectCard from '../../cards/project-card/project-card.component';
import { default as challenges } from './challenges-section.data';

import Grid from '@material-ui/core/Grid';

import useStyles from './challenges-section.styles';

const ChallengesSection = () => {
    const { challengeContainer, challengeCardContainer } = useStyles();

    return (
        <Grid direction='row' justify='center' alignItems='center' id='challenges'>
            <SectionHeader title='CODING CHALLENGES' />

            <Grid container className={challengeContainer} direction='row' justify="flex-start" alignItems="center" spacing={0}>
                {
                    challenges
                        .map(challenge =>
                            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} className={challengeCardContainer} >
                                <ProjectCard project={challenge} />
                            </Grid>
                        )
                }
            </Grid>
        </Grid>
    );
}

export default ChallengesSection;
