import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    nameContainer: {
        padding: 30
    },
    nameText: {
        display: 'block',
        color: '#008073',
        fontWeight: 200,
        fontSize: 28
    },
    titleText: {
        display: 'block',
        fontSize: 14,
        fontWeight: 300,
        color: '#795548'
    },
});

export default useStyles;
