import React from 'react';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import useStyles from './section-header.styles';

const SectionHeader = ({ title }) => {
    const { sectionHeader } = useStyles();

    return (
        <AppBar position="relative" className={sectionHeader}>
            <Toolbar>
                <Typography variant="h6" noWrap>{title}</Typography>
            </Toolbar>
        </AppBar>
    )
}

export default SectionHeader;
