import React from 'react';
import ProfilePicture from '../../../assets/img/me.jpg';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import MailIcon from '@material-ui/icons/Mail';

import LandingData from './landing-section.data';
import useStyles from './landing-section.styles';

const LandingSection = () => {
    const { landingPageContainer, infoContainer, infoText, missionContainer, missionText,
        avatarContainer, avatar, contactContainer, contactText, contactIcon, divider, textArea } = useStyles();

    const { headerText, mission, githubLink, linkedInLink, mail } = LandingData;

    return (
        <Grid className={landingPageContainer} direction='row' justify='center' alignItems='center'>
            <Grid item xs={12} className={`${infoContainer} ${textArea}`} >
                <Typography className={infoText} variant='body2'>{headerText}</Typography>
            </Grid>

            <Grid item xs={12} className={`${missionContainer} ${textArea}`} >
                <Typography className={missionText}>{mission}</Typography>
            </Grid>

            <Grid item xs={12} className={avatarContainer} >
                <Avatar alt="Remy Sharp" src={ProfilePicture} className={avatar} />
            </Grid>

            <Grid item xs={12} className={contactContainer} >
                <Typography className={contactText}>Contact me!</Typography>
            </Grid>

            <Divider className={divider} variant="middle" />

            <Grid item xs={12} className={contactContainer}>
                    <LinkedInIcon className={`${contactIcon}`} onClick={()=> window.open(`${linkedInLink}`, "_blank")} />
                    <GitHubIcon className={contactIcon} onClick={() => window.open(`${githubLink}`, "_blank")} />
                    <MailIcon className={contactIcon} onClick={() => window.open(`${mail}`, "_self")} />
            </Grid>
        </Grid>
    )
}

export default LandingSection;
