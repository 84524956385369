import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        paddingBottom: '25px',
    },
    textContainer: {
        [theme.breakpoints.down('xl')]: {
            marginLeft: '12vw',
            maxWidth: '70%',
        },
        [theme.breakpoints.down('lg')]: {
            marginLeft: '12vw',
            maxWidth: '80%',
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '8vw',
            maxWidth: '85%',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '8vw',
            maxWidth: '85%',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: '8vw',
            maxWidth: '85%',
        },
        marginTop: 15
    },
    quoteContainer: {
        paddingLeft: '1.5rem',
        borderLeft: '5px solid #795548',
        margin: '20px 0',
        color: '#795548',
        fontSize: '125%',

    },
    quoteText: {
        [theme.breakpoints.down('xl')]: {
            fontSize: 32
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 28
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 22
        },
        fontSize: '22px',
        lineHeight: '175%',
        margin: '30px 0 0',
        fontWeight: 400
    },

    aboutText: {
        [theme.breakpoints.down('xl')]: {
            fontSize: 22
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 20
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 16
        },
        lineHeight: '175%',
        fontWeight: 300,
        marginBottom: 15
    },

    focusText: {
        [theme.breakpoints.down('xl')]: {
            fontSize: 24
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 24
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 18
        },
        margin: '10px 0 0',
        fontWeight: 400
    },

    focusPoint: {
        [theme.breakpoints.down('xl')]: {
            fontSize: 20,
            fontWeight: 300
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 18
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 14
        },
        fontWeight: 400
    }
}));

export default useStyles;
