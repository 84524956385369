import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    navbar: {
        zIndex: 1100,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        color: 'rgb(71,171,91) !important',
        backgroundColor: '#fff !important',
        minHeight: 64
    },
    toolbar: {
        minHeight: 64
    },
    nameText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: '0 auto',
        lineHeight: '100%',
        paddingRight: 20,
        marginTop: 6
    },
    titleText: {
        fontSize: '12px',
        fontWeight: 300
    }
});

export default useStyles;
