import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    projectsContainer: {
        [theme.breakpoints.down('xl')]: {
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: '4vw',
        },
        [theme.breakpoints.down('lg')]: {
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: '4vw',
        },
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            justifyContent: 'space-around',
            marginLeft: 0
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'

        },
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
        },

        marginTop: 30,
        marginBottom: 30
    },
    projectCardContainer: {
        flexBasis: 0,

        [theme.breakpoints.up('xl')]: {
            marginleft: '2vw',
            marginRight: '2vw',
        },
        [theme.breakpoints.down('lg')]: {
            marginleft: '2vw',
            marginRight: '2vw',
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',

        },
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'center',
        },

        marginTop: 10,
        marginBottom: 10
    },
}));

export default useStyles;
