import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    cardContainer: {
        [theme.breakpoints.up('xs')]: {
            marginLeft: '5vw',
            marginRight: '5vw',
            padding: '25px 20px',
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '5vw',
            marginRight: '5vw',
            padding: '25px 20px',
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '5vw',
            marginRight: '5vw',
            padding: '25px 40px',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '10vw',
            marginRight: '10vw'
        },
        [theme.breakpoints.up('xl')]: {
            marginLeft: '15vw',
            marginRight: '15vw'
        },

        margin: theme.spacing(3, 2),
        boxShadow: '0px 4px 5px 3px rgba(0,0,0,0.36)'
    },
    cardTitle: {
        fontSize: '32px',
        color: '#795548',
        fontWeight: 300,
        marginBottom: 10
    },
    skillContainer: {
        marginTop: 30,
    },
    skill: {
        [theme.breakpoints.up('xs')]: {
            display: 'flex',
            justifyContent: 'space-around'
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
            justifyContent: 'flex-start'
        },
    }
}));

export default useStyles;
