import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    title: {
        marginBottom: 10
    },
    listContainer: {
        marginTop: 10,
        minHeight: 222
    }
});

export default useStyles;
