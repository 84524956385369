import React from 'react';

import SectionHeader from '../../section-header/section-header.component';
import { default as aboutData } from './about-section.data';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import useStyles from './about-section.styles';

const AboutSection = () => {
    const { container, textContainer, quoteContainer, quoteText, aboutText, focusText, focusPoint } = useStyles();
    const { missionStatement, introduction, career, personal, focusPoints  } = aboutData;

    return (
        <Grid className={container} direction='row' justify='center' alignItems='center' id='about'>
            <SectionHeader title='ABOUT ME' />

            <Grid container className={textContainer} direction='row' justify="flex-start" alignItems="center" spacing={0}>
                <Grid item xs={12} >
                    <blockquote className={quoteContainer}>
                        <Typography className={quoteText} variant='h2'>
                            {missionStatement}
                        </Typography>
                    </blockquote>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='body1' className={aboutText}>{introduction}</Typography>
                    <Typography variant='body1' className={aboutText}>{career}</Typography>
                    <Typography variant='body1' className={aboutText}>{personal}</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography className={focusText}>Currently focussing on:</Typography>
                    <Grid item xs={12} md={6}>
                        <div>
                            <List >
                                {
                                    focusPoints.map(point =>
                                        <ListItem>
                                            <ListItemIcon>
                                                <ArrowForwardIosIcon />
                                            </ListItemIcon>
                                            <Typography className={focusPoint}>{point}</Typography>
                                        </ListItem>
                                    )
                                }
                            </List>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AboutSection;
